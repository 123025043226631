import React from 'react';
import { Helmet } from 'react-helmet';
import DefaultPage from '../components/pages/DefaultPage';
import { StrapiMain } from '../services/useApi';
import AusstellerFormularPage from './AusstellerFormularPage';



function DownloadPage({ data }) {

    const targetDate = new Date(2024, 9, 16, 8, 0, 0);
    const currentDate = new Date();

    if (targetDate < currentDate) {
        return <AusstellerFormularPage />
    }
    return (
        <DefaultPage uri="aussteller" >
            <Helmet>
                <title>Bewerbung | Gallusmarkt in Grünberg</title>
                <meta name="description" content="Der Gallusmarkt in Grünberg ist einer der ältesten Jahrmärkte in Hessen. Benannt ist er nach dem Heiligen Gallus, dessen Namenstag am 16. Oktober gefeiert wird." />
            </Helmet>


            <div className="p-3 container">
                <h1>Bewerbung</h1>
                <Content formulars={data?.formulars?.data} />
            </div>
        </DefaultPage>
    )
}
export default DownloadPage

function Content({ formulars = [] }) {
    console.log("formulars", formulars)
    return (
        <div className="entry-content mb-5">
            <p>Hier finden Sie diverse Bewerbungsformulare.</p>
            <div className="mt-5">
                {formulars.map((i, k) =>
                    <Item {...i.attributes} key={k} />
                    // <div key={k}>
                    //     <h2>{i.caption}</h2>
                    //     <p>{i.subTitle}</p>
                    //     <div className="mb-5 pb-5 row">
                    //         {i.items.map((i, k) =>
                    //         )}
                    //     </div>
                    // </div>
                )}
            </div>
        </div>
    )
}

function Item({ name, url, ext, size }) {
    url = StrapiMain + url
    return (
        <div className="col-12 col-lg-6 p-2">
            <a className="w-100" href={url} target="_blank">
                <button className="w-100">
                    {name}
                    <br />
                    <small>{size} mb</small>
                </button>
            </a>
        </div>
    )
}