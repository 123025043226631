import React, { useState } from "react";
import { Helmet } from "react-helmet";
import DefaultPage from "../components/pages/DefaultPage";
import "./AusstellerFormularPage.scss";
import IframePage from "./IframePage/IframePage";

function AusstellerFormularPage({ }) {

    const [Url, setUrl] = useState(false)







    function handleClick(url) {
        setUrl(url)
    }


    const targetDate = new Date(2024, 9, 16, 8, 0, 0);
    const currentDate = new Date();



    if (Url) {
        return <IframePage url={Url} />
    }

    if (targetDate > currentDate && false) {
        return (<div className="AusstellerFormularPage">
            <h1>Noch nicht verfügbar.</h1>
        </div>)
    } else {
        return (
            <DefaultPage uri="aussteller" className="AusstellerFormularPage" >
                <Helmet>
                    <title>Bewerbung | Gallusmarkt in Grünberg</title>
                    <meta name="description" content="Bewerbungsformulare für den Krämermarkt und das Altstadtfest" />
                </Helmet>


                <div className="p-3 container">

                    <div className="mb-5">
                        <h1>Bewerbungsformulare</h1>
                        <p>Hier finden Sie diverse Bewerbungsformulare für den Grünberger Krämermarkt und das Altstadtfest.</p>
                    </div>


                    <div className="mb-5 pb-5">
                        {buttons.map((i, k) => <FormularItem {...i} key={k} onClick={handleClick} />)}
                    </div>
                </div>
            </DefaultPage>
        )
    }
}
export default AusstellerFormularPage


function FormularItem({ url, title, onClick = () => { } }) {


    return <div className="FormularItem mb-4 col-12 col-lg-8 col-xl-5 col-xxl-4">
        <span>
            {title}
        </span>
        <button onClick={() => onClick(url)}>Jetzt bewerben</button>
    </div>
}


let buttons = [
    {
        title: "Krämermarkt",
        url: "https://hessenapp.de/gallusmarkt-formular-eejfhhrufhe78zh7dkdokdofkeokcowko0cjkpeiokfokeogjkr0ie202493do/"
    },
    {
        title: "Altstadtfest",
        url: "https://hessenapp.de/bewerbung-altstadtfest-fkeiuf7ehfz7g73z7z437zh74ufgzhfeklksh7h38k4ugf4hervjrhuff-rjfu4hrhf44-x8rj8ff-2025/"
    },
]